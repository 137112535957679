<script setup lang="ts">
  import type { DropdownOption, DropdownOptions } from '@/types/dropdown';
  import { computed, ref } from 'vue';

  const selected = defineModel<number | string | number[]>('selected');
  const open = ref<boolean>(false);

  const props = defineProps<{
    label?: string;
    description?: string;
    placeholder?: string;
    options: DropdownOptions;
    error?: string;
    disabled?: boolean;
    multiple?: boolean;
    inputClass?: string;
  }>();

  function selectOption(item: DropdownOption) {
    if (props.multiple) {
      if (Array.isArray(selected.value)) {
        if (selected.value.includes(+item.key)) {
          selected.value = selected.value.filter((key) => key !== item.key);
        } else {
          selected.value.push(+item.key);
        }
        return;
      }
      return;
    }

    if (selected.value === item.key) {
      selected.value = undefined;
      open.value = false;
      return;
    }

    selected.value = item.key;
    open.value = false;
  }

  function isSelected(item: DropdownOption) {
    if (props.multiple) {
      return Array.isArray(selected.value) && selected.value.includes(+item.key);
    }

    return selected.value === item.key;
  }

  const selectedText = computed(() => {
    if (!selected.value || (Array.isArray(selected.value) && selected.value.length === 0)) {
      return props.placeholder ?? 'Selecteer een optie';
    }

    if (Array.isArray(selected.value)) {
      return selected.value.length + ' geselecteerd';
    }

    return props.options.find((option) => option.key === selected.value)?.value ?? props.placeholder ?? 'Selecteer een optie';
  });
</script>
<template>
  <div class="dropdown">
    <label v-if="label">{{ label }}</label>
    <label v-if="description" class="meta"> {{ description }}</label>
    <div class="content" v-outside="() => (open = false)">
      <input v-if="disabled" class="input" type="text" :value="selectedText" disabled />
      <div v-else :class="['input', { error, placeholder: !selected }, props.inputClass]" @click.prevent="open = !open">
        <span>
          {{ selectedText }}
        </span>
      </div>
      <span v-if="error" class="error-message">
        {{ error }}
      </span>
      <ul :class="{ open }">
        <template v-for="item in options" :key="item.key">
          <li @click.prevent="selectOption(item)" :class="{ '-selected': isSelected(item) }">
            {{ item.value }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .active {
    color: var(--Primary-500);
    font-weight: 500;
    cursor: pointer;
  }
</style>
